import React from 'react';
import { useDoubleTap } from 'use-double-tap';

export default function Canvas(props) {
    const handleDoubleTap = useDoubleTap(props.handleDoubleClick)
    return <canvas id="canvas" 
            style={{
                touchAction: "none",
            }}
            width={props.canvasSize}
            height={props.canvasSize}
            onPointerDown={props.handleMouseDown}
            onPointerUp={props.handleMouseUp}
            onPointerMove={props.handleMouseMove}
            onDoubleClick={props.handleDoubleClick}
            {...handleDoubleTap}
        />;
}