export default function OutlineButton(props) {
    return <button type={props.type} id={props.id} onClick={(event) => props.buttonEvent(event)} className={`${!props.show ? 'hidden' : ''} px-8 py-1.5 md:py-2 md:h-12 bg-transparent font-medium text-primary cursor-pointer text-lg border border-secondary-dark hover:bg-gray hover:text-primary transition focus:bg-gray focus:text-primary ${props.classes}`} >
        {props.text}
    </button>;
}

OutlineButton.defaultProps = {
    type: 'button',
    show: true,
    buttonEvent: ()=>{},
    classes: '',
    id: '',
    disable: false
}