export default function PrimaryButton(props) {
    return <button type={props.type} id={props.id} onClick={!props.disabled ? props.buttonEvent : ()=>{}} className={`${!props.show ? 'md:hidden' : ''} px-8 py-1.5 md:py-2 md:h-12 font-medium cursor-pointer text-md md:text-lg border hover:bg-white hover:text-primary hover:border-primary transition focus:bg-gray focus:text-primary font-semibold ${props.disabled ? 'bg-disabled text-black border-disabled' : 'bg-primary text-white border-primary'} ${props.classes}`}>
        {props.text}
    </button>;
}

PrimaryButton.defaultProps = {
    type: 'button',
    show: true,
    buttonEvent: ()=>{},
    classes: '',
    id: '',
    disable: false
}